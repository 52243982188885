import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/",
    exact: true,
    layout: "info",
    component: Loadable({
      loader: () => import("./pages/home"),
      loading: LoadingComponent
    })
  },
  {
    path: "/features",
    exact: true,
    layout: "info",
    component: Loadable({
      loader: () => import("./pages/features"),
      loading: LoadingComponent
    })
  },
  {
    path: "/pricing",
    exact: true,
    layout: "info",
    component: Loadable({
      loader: () => import("./pages/pricing"),
      loading: LoadingComponent
    })
  },
  {
    path: "/privacy-policy",
    exact: true,
    layout: "info",
    component: Loadable({
      loader: () => import("./pages/privacy"),
      loading: LoadingComponent
    })
  }
];
