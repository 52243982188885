import { call, put, takeLatest } from 'redux-saga/effects';
import {
	checkSlug as checkSlugService,
} from './service';
import { actionTypes } from './reducer';

function* checkSlug(action) {
	const { payload } = action;

	try {
		const data = yield call(checkSlugService, payload);
		yield put({type: actionTypes.GET_SLUG_REQUEST_SUCCESS, data});
	} catch(error) {
		yield put({type: actionTypes.GET_SLUG_REQUEST_FAILED, error});
	}
}


// =========================
// ------- WATCHERS --------
// =========================

function* watchGetSlug() {
	yield takeLatest(actionTypes.GET_SLUG_REQUEST, checkSlug);
}

export default [
	watchGetSlug(),
];


