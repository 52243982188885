import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Layout, Menu, Row, Col } from 'antd';
import airshipLogo from "./images/airship-logo-white.png";
import "./App.less";

const { Header, Content } = Layout;

// You can get the current config object
// const currentConfig = Auth.configure();

class App extends Component {
  state = {
    activeKey: this.props.match.path
  }

  renderBlankPage() {
    return (
      <Layout style={{ minHeight: "100vh" }} className="dashboard">
        {this.props.children}
      </Layout>
    );
  }

  handleChangeNav = e => {
    this.setState({ activeKey: e.key });
  }

  renderInfoPage() {
    return (
      <Layout style={{ minHeight: '100vh', background: 'inherit' }}>
        <Header style={{ position: 'fixed', zIndex: 10, width: '100%', height: 50 }}>
          <Row type="flex" justify="space-between">
            <Col xs={8} sm={12}>
              <NavLink to="/">
                <img src={airshipLogo} alt="Airship Logo" className="logo" />
              </NavLink>
            </Col>
            <Col xs={16} sm={12}>
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[this.state.activeKey]}
                style={{ lineHeight: '50px', background: 'none', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Menu.Item key="/" onClick={this.handleChangeNav}>
                  <NavLink to="/">
                    <span>Home</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/features" onClick={this.handleChangeNav}>
                  <NavLink to="/features">
                    <span>Features</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/pricing" onClick={this.handleChangeNav}>
                  <NavLink to="/pricing">
                    <span>Pricing</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        </Header>
        <Content style={{ marginTop: 50 }}>
          {this.props.children}
        </Content>
      </Layout>
    );
  }

  render() {
    const { layout } = this.props;
    return (layout === "info") ? this.renderInfoPage() : this.renderBlankPage();
  }
}

export default withRouter(App);