import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { Auth } from "aws-amplify";

// retrieve temporary AWS credentials and sign requests
Auth.configure({
	// identityPoolId: 'ap-southeast-1:a1646407-2abc-43c5-898d-a00dd1905741',  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	region: 'ap-southeast-1',
	userPoolId: 'ap-southeast-1_3J8HAjQgg', 				// OPTIONAL - Amazon Cognito User Pool ID
	userPoolWebClientId: '3nenip37r45j20gs5gtrk6pfir', 	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	mandatorySignIn: true,                              // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
});

ReactDOM.render(
	<Provider store={store.store}>
		<Routes />
	</Provider>, 
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
