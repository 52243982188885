const keyname = 'mcheetahTKN';

export const setToken = accessToken => {
  window.localStorage.setItem(keyname, accessToken);
  return accessToken;
};

export const getToken = () => {
  return window.localStorage.getItem(keyname);
};

export const deleteToken = () => {
  return window.localStorage.removeItem(keyname);
};