import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./Private";
import PublicRoute from "./Public";

class Routes extends Component {
  renderNotFound() {
    return (
      <h1>Page not found.</h1>
    )
  }

  renderApp() {
    return (
      <Router>
        <Switch>
          {routes.map((route, i) => {
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoute key={i} {...route} />;
          })}
        </Switch>
      </Router>
    );
  }

  render() {
    let found = true;

    return (
      <React.Fragment>
        { found ? this.renderApp() : this.renderNotFound() }
      </React.Fragment>
    )
  }
};

export default Routes;