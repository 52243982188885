import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import CustomSignUp from "../modules/auth/pages/register";
import Main from "../App";

import { Authenticator, Greetings, ForgotPassword, SignIn, SignUp } from "aws-amplify-react";

const PrivateRoute = ({ component: Component, layout, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={ props => (

          <Authenticator hide={[Greetings,ForgotPassword, SignIn, SignUp]}>
            <CustomSignUp override={'SignUp'} />
            <Main auth={auth} layout={layout}>
              <Component {...props} />
            </Main>
          </Authenticator>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object
};

export default PrivateRoute;
