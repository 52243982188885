import { combineReducers } from "redux";

import auth from "../modules/auth/reducer";

// const authPersistConfig = {
//   key: "auth",
//   storage
// };

export default combineReducers({
  // auth: persistReducer(authPersistConfig, auth),
  auth
});
