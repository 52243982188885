// Action Types
export const actionTypes = {
  GET_SLUG_REQUEST: "GET_SLUG_REQUEST",
  GET_SLUG_REQUEST_SUCCESS: "GET_SLUG_REQUEST_SUCCESS",
  GET_SLUG_REQUEST_FAILED: "GET_SLUG_REQUEST_FAILED",
};

// Reducer
const initialState = {
  checkingSlug: false,
  slugAvailable: false,
  errors: null,
};

export const actionCreators = {
  getSlugRequest: (slug) => ({ type: actionTypes.GET_SLUG_REQUEST, payload: slug })
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case actionTypes.SAVE_SLUG:
    //   if(state.slugs.indexOf(action.payload) === -1) {
    //     return Object.assign({}, state, {
    //       slugs: [...state.slugs, action.payload]
    //     });
    //   }

    //   return state;

    case actionTypes.GET_SLUG_REQUEST:
      return {
            ...state,
            checkingSlug: true
      };

    case actionTypes.GET_SLUG_REQUEST_SUCCESS:
      return {
            ...state,
            checkingSlug: false,
            slugAvailable: action.data.isAvailable
      };

    case actionTypes.GET_SLUG_REQUEST_FAILED:
      return {
            ...state,
            checkingSlug: false,
            errors: action.message
      };

    default:
      return state;
  }
};
