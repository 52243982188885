import React from 'react';
import { Spin, Icon, Row, Col } from 'antd';

const LoadingComponent = ({ isLoading, error }) => {	
	const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

	return (
		<Row type="flex" justify="center" align="top">		
			<Col span={1}>
				<Spin indicator={antIcon} />
			</Col>
		</Row>
	);
};

export default LoadingComponent;