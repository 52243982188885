import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/register",
    exact: true,
    component: Loadable({
      loader: () => import("./pages/register"),
      loading: LoadingComponent
    })
  }
];