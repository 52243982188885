import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { NavLink } from "react-router-dom";
import debounce from "../../../../utils/debounce";
import uuidv1 from "uuid/v1";
import "./index.less";

import { Auth } from "aws-amplify";

const FormItem = Form.Item;

class Page extends Component {
  state = {
    loading: false,
    confirmSlugDirty: false,
    confirmDirty: false
  };

  capitalizeWords = (text) => text.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { console.log(a); return a.toUpperCase(); });

  //validations
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  };

  validateSlugExists = (rule, value, callback) => {
    const { slugAvailable } = this.props.auth;
    if (!slugAvailable) {
      callback("Subdomain is not available");
    } else {
      callback();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {

          await Auth.signUp({
              username: uuidv1(),
              password: values.password,
              attributes: {
                  email: values.username.toLowerCase(),
                  "custom:tenant_slug": values.slug.replace(/ /g,"").toLowerCase(),
                  "custom:tenant_name": this.capitalizeWords(values.company_name)
              },
              validationData: [
                // {
                //   Name: "company",
                //   Value: values.company_name.toLowerCase()
                // },
                // {
                //   Name: "tenant_slug",
                //   Value: values.slug.toLowerCase()
                // }
              ]
            });
          this.setState({ loading: false });
          message.success("We've sent a verification link to your email, kindly verify before logging in.");

        } catch(e) {
          console.log(e);
          if (e.code === "UsernameExistsException") {
            // Send email again
            Auth.resendSignUp(values.username)
              .then(() => {
                message.warning(
                  "You've already signed up with that email, if you haven't verified it we've sent you another verification email."
                );
              })
              .catch(e => {
                if (e.code !== "InvalidParameterException") {
                  message.warning(
                    "You've already signed up with that email and verified it."
                  );
                }
              });
            this.setRedirect();
          } else if (!!e.message) {
            alert(e.message);
          } else {
            alert(e);
          }

          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  };

  handleCheckSlug = debounce(slugInput => {
    const slug = slugInput.toLowerCase().replace("express","").replace(/ /g,"");
    if(slugInput.length > 0) this.props.getSlugRequest({ slug });
  },500);

  handleChangeCompany = e => {
    const slugInput = e.target.value;
    const slug = slugInput.toLowerCase().replace("express","").replace(/ /g,"");
    this.setState({ confirmSlugDirty : true });
    this.props.form.setFieldsValue({ slug });
  };

  handleChangeSlug = debounce(slugInput => {
    const slug = slugInput.toLowerCase().replace(/ /g,"");
    this.setState({ confirmSlugDirty : true });
    this.props.form.setFieldsValue({ slug });
    if(slugInput.length > 0) this.props.getSlugRequest({ slug });
  },500);

  renderForm() {
    const { confirmSlugDirty } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { slugAvailable, checkingSlug } = this.props.auth;

    return (
      <Form
        layout="vertical"
        onSubmit={this.handleSubmit}
        className="auth-form"
        autoComplete="off"
      >
        <h2 className="text-center">Start your free trial with Airship</h2>
        <FormItem>
          {getFieldDecorator("company_name", {
            rules: [
              {
                required: true,
                message: 'Please enter your full company name',
              }
            ]
          })(<Input placeholder="Company Name" onInput={this.handleChangeCompany} onChange={(e) => this.handleCheckSlug(e.target.value)} size="large" disabled={this.state.loading} />)}
        </FormItem>
        <FormItem
          hasFeedback
          validateStatus={ (confirmSlugDirty && !!getFieldValue('slug') ) ? (checkingSlug ? 'validating' : (slugAvailable ? 'success' : 'error')) : null }
          help={ (confirmSlugDirty && !!getFieldValue('slug')) ? (checkingSlug ? 'Checking for availability...' : (slugAvailable ? 'Subdomain is available' : 'Subdomain is not available')) : '' }
        >
          {getFieldDecorator("slug", {
            rules: [
              {
                required: true,
                message: 'Please enter your subdomain',
              },
              {
                pattern: /^\S*$/,
                message: 'Your subdomain should only contain letters, not even spaces'
              },
              {
                validator: this.validateSlugExists,
              },
            ]
          })(<Input addonBefore="https://" addonAfter=".airship.work" placeholder="yourcompany" size="large" disabled={this.state.loading} onChange={(e) => this.handleChangeSlug(e.target.value)} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: 'Please enter your email.',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address.'
              }
            ]
          })(<Input type="email" size="large" placeholder="Email" disabled={this.state.loading} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password", {
            initialValue: 'password',
            rules: [
              {
                required: true,
                message: 'Please choose a password.'
              },
              {
                min: 6,
                message: 'Password should be at least 6 characters.'
              },
              {
                validator: this.validateToNextPassword,
              },
            ]
          })(
            <Input
              type="password"
              size="large"
              placeholder="Password"
              disabled={this.state.loading}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("confirm_password", {
            initialValue: 'password',
            rules: [
              {
                required: true,
                message: 'Please type your password again.',
              },
              {
                validator: this.compareToFirstPassword,
              }
            ]
          })(
            <Input
              type="password"
              size="large"
              placeholder="Type Your Password Again"
              disabled={this.state.loading}
            />
          )}
        </FormItem>
        <FormItem>
          <Row gutter={8}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              style={{textAlign: 'right'}}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="auth-main-button"
                loading={this.state.loading && this.props.auth.checkingSlug}
              >
                Create a Courier
                </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <div className="auth-container">
        <NavLink to="/"><i className="fas fa-times fa-2x" style={{color: '#666',position:'absolute',top:15,right: '1em'}}></i></NavLink>
        <Row type="flex" justify="center">
          <Col xs={20} sm={12} md={10} lg={8}>
            <Row type="flex" justify="center" align="middle" style={{height: '100vh'}}>
              <Col>
                {this.renderForm()}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

const WrappedNormalSignUp = Form.create()(Page);

export default WrappedNormalSignUp;
